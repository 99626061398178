<template>
    <workTable ref="workTable" :filterIndex="1" :table-title-json="tableTitleJson"></workTable>
</template>

<script>
    import workTable from './layout/workTable.vue'
    import tableScrollTo from '#/js/mixins/tableScrollTo.js';
    import floatingListTableTitle from '#/js/config/floatingListTableTitle.json';
    export default {
        name: "FloatingListMyRecommend",
        components: {
            workTable
        },
        data(){
            return{
                tableTitleJson: floatingListTableTitle.myrecommend
            }
        },
        mixins: [tableScrollTo],
        activated() {
            this.$refs.workTable.tableTitleInit();
            this.$refs.workTable.resize();
            let _this = this;
            window.onresize = function () {
                _this.$refs.workTable.resize();
            }
            this.tableScrollTo();
        },
    }
</script>

<style scoped>

</style>
